import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Container from 'components/Container';
import { Button } from '@mui/material';

// import pdf1 from 'assests/Pdf/Sportsclub2023/pdf1.pdf'
// import pdf2 from 'assests/Pdf/Sportsclub2023/pdf2.pdf'
// import pdf3 from 'assests/Pdf/Sportsclub2023/pdf3.pdf'

import Achivements from 'views/Achivements/Achivements';



const Achievements = () => {

    const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const pdf1 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/Sportsclub2023/pdf1.pdf`;
  const pdf2 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/Sportsclub2023/pdf2.pdf`;
  const pdf3 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/Sportsclub2023/pdf3.pdf`;


  const mock = [
    {
      name: 'Name List of Prize Winners 1 to 5',
      title: 'Click here',
      
      href:pdf1,
    },
    {
      name: '6 - 8 Group Game Winners',
      title: 'Click here',
     
      href:pdf2,
    },
    {
      name: '9 - 12 Group Game Winners',
      title: 'Click here',
     
      href:pdf3,
    },
  
  ];
  
  const theme = useTheme();

  return (
    <Container>
      <Box>
        <Box marginBottom={4}>
          <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
            fontWeight={700}
          >
            LIST OF PRIZE WINNER
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                width={1}
                height={1}
                component={Card}
                boxShadow={0}
                variant={'outlined'}
                bgcolor={'alternate.main'}
              >
                <CardContent sx={{ padding: 3 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  
                    <Button 
                    href={item.href}>
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.title}
                      primaryTypographyProps={{
                        variant: 'h6',
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{ variant: 'subtitle1' }}
                    />
                    </Button>
                  </ListItem>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Achievements;

