import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { Main } from 'layouts';
import Container from 'components/Container';
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

// import p1 from 'assests/Photos/PngItem_78519.png';
// import p2 from 'assests/Photos/milestones292139.png';

const analytics = Analytics({

  

  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-W1G8LXHX5C']
    })
  ]
})
const analyticsa = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})
analytics.page()
analyticsa.page()




const Achivements = () => {
  
 const [config, setConfig] = useState({ base_image_url: '' });
useEffect(() => {
  fetch('/config.json')
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_url}/home/home-static/static-images/all-static-images/Achievements/PngItem_78519.webp`;
const p2 = `${config.base_image_url}/home/home-static/static-images/all-static-images/Achievements/milestones292139.webp`;

const mock = [  
  {
    title: 'Academic',
    subtitle:
      'There is no secret to success. It is the result of hard work, preparation and learning from failure.',
    icon: 'https://www.pngkey.com/png/full/509-5098931_as-olympiad-icon.png',
    href : '/student-result-achievements'
  },
  {
    title: 'Co-Scholastic',
    subtitle:
      'The school organises Co-curricular Activities CCA for all classes giving them plethora of....',
    icon: p1,
    href : '/achi-co-sch'
  },
  {
    title: 'Scholastic',
    subtitle:
      'National Public School, Yeshwanthpur has grown into one of the progressive premier educational....',
    icon: p1,
    href : '/olympiad'
  },
  {
    title: 'Trail Blazers',
    subtitle:
      'The Olympiad exams are competitive examinations held at national and international level....',
    icon: 'https://www.pngkey.com/png/full/509-5098931_as-olympiad-icon.png',
    href : '/trailblazer'
  },
  {
    title: 'Milestones',
    subtitle:
      'A Testament to Our Legacy of Excellence',
      icon: p2,
    href : '/milestones2024'
  },

];
  
  return (
    <Main>
          <Container  maxWidth={0.9}  paddingTop={'0 !important'}>
            <Grid>
                <br />
               
            </Grid>
            
    <Box>
      
      <Box marginBottom={4}>
        {/* <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
        >
          Compatibility
        </Typography> */}
        <br />
        <br/>
        <br />
        <Typography fontWeight={700} variant={'h4'} align='center'>
          Achievements
        </Typography>
        <br/>
        <br />
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={3} key={i}>
            <Box
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
              <Button
               href={item.href}>
                <Box
                  component={Avatar}
                  width={80}
                  height={80}
                  marginBottom={2}
                  src={item.icon}
                  
                />
                </Button>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  fontWeight={500}
                  align={'center'}
                >
                  {item.title}
                </Typography>
                <Button href={item.href}>
                <Typography align={'center'} color="text.secondary">
                  {item.subtitle}
                </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
    </Container>
    </Main>
  );
};

export default Achivements;

